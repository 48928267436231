import { Turbo } from '@hotwired/turbo-rails';

import './packs/notifications';
import './packs/show-report';
import './packs/triage';

// Load react components
import './utils/react-loader';

// Turbo causes rendering bugs with our existing javascript forms. Require explicit opt in for Turbo.
Turbo.session.drive = false;
